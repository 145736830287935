<template>
    <div class="goods-shoot-content">
        <div>
<!--            <div class="goods-shoot-header">-->
<!--                <span style="margin-bottom: 28px;">素材下载</span>-->
<!--            </div>-->
            <!-- <div class="title">
                <span>所有订单</span>
            </div> -->
            <div class="item-content" style="margin-top: 30px;">
                <div class="item-title">上传视频</div>
                <div class="upload text">
                    <el-button plain onclick="uploadFile.click()">本地上传</el-button><br>
                    <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                            accept="video/mp4, .mov" title=""
                            @change="changeFile($event)">
                  
                </div>

            </div>
            <div class="item-content" style="margin-left: 130px;">
                <div v-for="(item,index) in video_score" :key="index" style="margin-right: 10px;">             
                    <span class="file-name" :title="item.name">{{item.name}}</span>
                        <i class="iconfont del-icon" @click="Del(index,item)">&#xe651;</i>
                </div>
            </div>
   
            <div class="item-content">
                <div class="item-title"></div>
                <span>视频库</span>
            </div>
            <div class="item-content">
                <div class="back_add" style="margin-left: 130px;width: 100%;">
                <div class="threeImg">
                    <div class="iconleft"  @click="zuohua">
                                <i class="el-icon-arrow-left" style="color: #fff;font-weight: bolder;padding-left: 5px;"></i>
                            </div>
                        <div class="Containt">
                            <ul :style="{'left':calleft + 'px'}"  style="padding-left: 0px;">
                                <li v-for="(item,index) in superurl" :key="index" style="position: relative;"
                                 
                                  @click="active(index,item)">
                                    <span :class="{ 'active':spliceIndex == index }" style="position: absolute;"></span>
                                    <img :src="item.cover"  />
                                    
                                </li>
                            </ul>
                        </div>
                          <div class="iconright" @click="youhua">
                                <i class="el-icon-arrow-right" style="color: #fff;font-weight: bolder;padding-left: 5px;"></i>
                            </div>
                    </div>
                </div>
            </div>
            <div  class="item-content">
                 <div class="item-title">标题</div>
                 <el-input class="item-input max"  v-model="videoFormData.label"
                            placeholder="请输入标题(0-200个字)" ></el-input>
            </div>
            <div class="item-content" style="align-items: baseline">
                <div class="item-title">描述</div>
                <el-input class="item-input max" type="textarea"  :rows="8" v-model="videoFormData.describe"
                        placeholder="请输入5-1000个字的描述" ></el-input>
                
            </div>
            <div class="item-content">
                    <div class="item-title">本文目标人群</div>
                      <el-select  v-model="videoFormData.crowd"  placeholder="请选择" style="width: 375px" clearable >
                        <el-option v-for="item in crowdList" :key="item.value" :label="item.label" :value="item.value" v-if="module_id != 64"></el-option>
                        <el-option v-for="item in crowdListEn" :key="item.value" :label="item.label" :value="item.value" v-if="module_id == 64"></el-option>
                    </el-select>
                </div>
        </div>
        <div  style="text-align: center">
            <el-button @click="goBack" :disabled="submit_text === '上传中'">返回</el-button>
            <el-button type="primary" @click="saveGoodsVideoShoot" :disabled="submit_text === '上传中'">{{submit_text}}</el-button>
        </div>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {uploadVideo,createArticle,stuOpSaveArticle,stuOpArticleDetail, collectMerge} from '@/utils/apis'
    export default {
        name: "GoodsVideoShoot",
        data() {
          return {
              module_id: 0,
              //文件名称
              filesName: '未选择任何文件',
              //分片上传
              uploadInstance: null,
              material: '',
              //提交id
              id: null,
              //提交文字
              submit_text: '提交',
              //要下载的素材
              downloadMaterial: '', 
              spliceIndex:null,
              labelUrl:'',
              isAllClass:true,
              labelCover:'',
              video:[],
              opId: Number(this.$route.query.id) || null,
               editId: Number(this.$route.query.edit_id) || null,
               crowdList:[
                    {
                        value:0,
                        label:'手机'
                    },
                    {
                        value:1,
                        label:'美搭'
                    },
                     {
                        value:2,
                        label:'型男'
                    },
                     {
                        value:3,
                        label:'数码'
                    },
                     {
                        value:4,
                        label:'美容'
                    },
                     {
                        value:5,
                        label:'母婴'
                    },
                     {
                        value:6,
                        label:'旅行'
                    },
                     {
                        value:7,
                        label:'居家'
                    },
                    {
                        value:8,
                        label:'鞋靴'
                    },
                     {
                        value:9,
                        label:'家用电器'
                    },
                     {
                        value:10,
                        label:'电脑办公'
                    },
                ],
                crowdListEn:[
                    {
                        value:0,
                        label:'Mobile phone'
                    },
                    {
                        value:1,
                        label:'Beauty matching'
                    },
                     {
                        value:2,
                        label:'Stylish men'
                    },
                     {
                        value:3,
                        label:'Digital'
                    },
                     {
                        value:4,
                        label:'Cosmetology'
                    },
                     {
                        value:5,
                        label:'Maternal and infant'
                    },
                     {
                        value:6,
                        label:'Travel'
                    },
                     {
                        value:7,
                        label:'Stay at home'
                    },
                    {
                        value:8,
                        label:'Shoes and boots'
                    },
                     {
                        value:9,
                        label:'Household appliances'
                    },
                     {
                        value:10,
                        label:'Computer office'
                    },
                ],
               videoFormData:{
                    label:'',
              
                    describe:'',
          
                    crowd:null,
                },
                video_score:[],
              superurl: [
                {
                    url: '',
                    img: require('../../../../../assets/pictureA.png'),
                },
                {
                    url: '',
                    img: require('../../../../../assets/pictureB.png'),
                },
                {
                    url: '',
                    img: require('../../../../../assets/pictureC.png'),
                },
                {
                    url: '',
                    img: require('../../../../../assets/pictureD.png'),
                },
                {
                    url: '',
                    img: require('../../../../../assets/pictureE.png'),
                },
               
            ],
            calleft:0
          }
        },
        created() {
            this.getVideo()
            this.module_id = this.$route.query.module_id;
        },
        
        methods: {
            goBack() {
                this.$router.push('/student/examing/softPromotionIndex');
            },
        getEditList(){
             let param = {
                    id: this.editId
                }
                stuOpArticleDetail(param).then(res => {
                 let List = JSON.parse(res.data.video)
                    this.videoFormData.label = res.data.title   
                    this.videoFormData.describe = res.data.description   
                    this.videoFormData.crowd = parseInt(res.data.people)
                    console.log(List,'8888')
                     this.video = []
                    for(let i=0;i<List.length;i++){
                        if(List[i].name ==null||List[i].name==undefined){
                            
                            for(let j=0;j<this.superurl.length;j++){
                                if(List[i].url ==this.superurl[j].url){
                                      this.labelUrl = List[i].url
                                      this.labelCover = List[i].cover
                                    // // this.video.push({url:'',cover:''})
                                    
                                    // debugger
                                      console.log(this.labelUrl,'asdasssd')
                                }                               
                            }
                        }else{
                             let obj = {
                                url: List[i].url,
                                name: List[i].name,
                                cover: List[i].cover,
                            }
                            this.video_score.push(obj)
                        }
                    }
                    for(let i = 0;i<this.superurl.length;i++){
                        if(this.labelUrl == this.superurl[i].url){
                             this.spliceIndex = i
                        }
                    }
                    console.log( this.spliceIndex,'nnnn')
                    this.video = [{
                        url: this.labelUrl,
                        cover: this.labelCover
                    }]
                    // for(let i=0;i<this.labelUrl.length;i++){
                    //    this.video[i].url = this.labelUrl
                    //    this.video[i].cover = this.labelCover
                    // }
                    // console.log(this.labelUrl,'55555')
                    // console.log(this.video,'66666')
                   
                }).catch(err => {
                    console.error(err)
                })
        },
  
        Del(index,item){
            this.video_score.splice(index,1)
        },
 
        getVideo() {
            createArticle().then((res) => {
                this.superurl = res.data
                console.log(res.data,'6665555')
                if (this.editId!=null) {
                    this.getEditList()
                }
            
            }).catch((err) => {
                console.log(err);
            });
        },
          
            //上传素材
            changeFile(event) {
                if (!event.target.files[0].size) {
                    return this.$message.warning('文件大小异常，请检查文件');
                }
                if (event.target.files[0].size > 1024 * 1024 * 100) {
                    this.$message.warning('文件超过100M');
                    return;
                } else {
                    let chunkSize = 1024 * 1024 *10;
                    let file = event.target.files[0];
                    this.uploadInstance = new Upload(file, chunkSize, uploadVideo);
                    this.filesName = file.name;
                    this.submit_text = '上传中';
                    let index = 0;
                    this.uploadSlice(index);
                }
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    collectMerge({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.submit_text = '提交';
                            this.material = res.data.src;
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                             let obj = {
                                url: res.data.src,
                                name: this.filesName,
                                cover: require('../../../../../assets/pictureD.png'),
                            }
                          
                            this.video_score.push(obj)
                            
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.material = res.data.src;
                            this.submit_text = '提交';
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                             let obj = {
                                url: res.data.src,
                                name: this.filesName,
                                cover: require('../../../../../assets/pictureD.png'),
                            }
                            this.video_score.push(obj)
                
                            
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            imgFun(item){
                console.log(item)
            },
            active(index,item){
                if(index == this.spliceIndex){
                    this.spliceIndex = null
                }else{
                    this.spliceIndex = index
                }

                if( this.labelUrl == item.url){
                    this.labelUrl = ''
                }else{
                     this.labelUrl = item.url
                }
                // console.log(item.cover,'9999')
            //     if(item.cover){
            //         this.labelCover = ''
            //         // console.log( this.labelCover,'uuuuuuu')
            //     }else if(!item.cover){
            //          this.labelCover = item.cover
            //         //   console.log( this.labelCover,'yyyyyy')
            //     }
             this.labelCover = item.cover
              console.log(this.labelUrl,'yyyy' )
            },

            // active(index,item){
                
            //       //赋值class
            //     // this.isAllClass = false
            //     if (this.spliceIndex.includes(index)) {
            //         //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
            //         //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
            //         this.spliceIndex = this.spliceIndex.filter(function(ele) {
            //             return ele != index;
            //         });
            //     } else {
            //         this.spliceIndex.push(index);
            //     }
            //     if (this.labelUrl.includes(item.url)) {
            //         //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
            //         //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
            //         this.labelUrl = this.labelUrl.filter(function(ele) {
            //             return ele != item.url;
            //         });
            //     } else {
            //             this.labelUrl.push(item.url);
            //     }
            //      if (this.labelUrl.includes(item.cover)) {
            //         //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
            //         //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
            //         this.labelCover = this.labelCover.filter(function(ele) {
            //             return ele != item.cover;
            //         });
            //     } else {
            //             this.labelCover.push(item.cover);
            //     }
            // },
             //移动
            //开始移动
            starmove() {
                this.calleft -= 255;
                if (this.calleft < -1200) {
                    this.calleft = 0
                }
            },
            //点击按钮左移
            zuohua() {
                if(this.superurl.length>=5){
                    this.calleft -= 255;
                    if (this.calleft < -400) {
                        this.calleft = 0
                    }
                }
                console.log(this.superurl,'66666')
               
            },
            //点击按钮右移
            youhua() {
                  console.log(this.superurl.length,'7777')
            if(this.superurl.length>=5){    
                this.calleft += 255;
                    if (this.calleft > 0) {
                        this.calleft = -255
                    }
                }    
            },
            //提交
            saveGoodsVideoShoot() {
                if(this.videoFormData.label.length>200){
                    this.$message.error('标题字数长度1-200个字符')
                    return
                }
                 if(this.videoFormData.describe.length<5||this.videoFormData.describe.length>1000){
                    this.$message.error('描述为5-1000个字')
                    return
                }


                this.video = [{
                    url: this.labelUrl,
                    cover: this.labelCover
                }]
                console.log(this.labelCover,'asdsasa')
                // for(let i=0;i<this.labelUrl.length;i++){
                //     this.video.push({url:'',cover:''})
                //     this.video[i].url = this.labelUrl[i]
                //     this.video[i].cover = this.labelCover[i]
                // }
                // console.log(this.video_score,'666')

                // console.log( this.video,'888')
                this.video = this.video_score.concat(this.video)
                  console.log( this.video,'888')
                let param = {
                     op_id:this.opId,
                     video : this.video,
                     title : this.videoFormData.label,
                     description:this.videoFormData.describe,
                     people:this.videoFormData.crowd
                }
                // if (this.$route.query.id) {
                //     param.course_id = Number(this.$route.query.course_id);
                //     param.op_id = Number(this.$route.query.id);
                // }
                // if (this.id) {
                //     param.id = this.id;
                // }
                stuOpSaveArticle(param).then((res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.$router.go(-1)
                    }
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
         .title{
            border-bottom: 2px solid #F1F5FF;
            padding-bottom: 15px;
        }
        .item-input {
            &.max {
                width: 475px;
            }

            &.medium {
                width: 250px;
            }

            &.mini {
                width: 150px;
            }

            &.sex {
                width: 130px;
            }

            &.tiny {
                width: 120px;
            }
        }
         .item-content {
               display: flex;
                align-items: center;
                margin-bottom: 16px;
                  .item-title {
                    width: 120px;
                    text-align: right;
                    margin-right: 10px;
                }
                
         }

        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .task-table {
        border: 1px solid #E5E5E5;
        margin-top: 16px;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #F6F6F6;
            border-bottom: 1px solid #EEEEEE;
            box-sizing: border-box;
            padding: 0 40px;
        }
        .upload {
            display: flex !important;
            align-items: center;
        }
        .text {
            display: inline-block;
            &:nth-child(1) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 550px;
            }
        }
        .table-body {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            padding: 0 40px;
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        // display: inline-block;
        flex: 1;
        width: 1%;
        color: #666;
        margin-left: 10px;
    }
    .is-plain {
        background: #FFF;
        border-color: #1CB064;
        color: #1CB064;
        border-radius: 2px;
    }
    .download-materials {
        color: #2461EF;
        cursor: pointer;
    }
    .active {
        color: #038bc6 !important;
        background:url("../../../../../assets/Slice.png") ;
        background-repeat:no-repeat;
        background-color: #00000054;
        background-position:center center;
        height: 119px;
       width: 214px;
        
    }


#divAdd {
    background-color: #ededed;
    /*width: 100%;*/
    /*min-width: 1200px;*/
    width: 1000px;
    margin: 0px auto;
}
 
.divAdd-con {
    margin: 0px auto;
    width: 1000px;
    overflow: auto;
    padding: 30px 0px;
}
 
.divAdd-con img {
    float: left;
}
 
.indexrt {
    margin: 0px 40px;
}
 
.divAddleft img {
    float: left;
    margin-bottom: 7px;
}
 
.divAddleft {
    float: left;
    display: inline;
    width: 370px;
}
 
.divAddrt {
    float: right;
    display: inline;
    margin-top: 7px;
}
 
.back_add {
    // background-color: #ededed;
}
 
.divAdd-con img {
    border: none;
}
 
 
a:focus,
a:hover {
    color: #23527c;
}
 
 
.threeImg {
    height: 158px;
    // background: #ededed;
    // border-bottom: 3px solid #4679B2;
    width: 1082px;
    position: relative;
}
 
.threeImg .Containt ul {
    margin: 0 auto;
    width: 2400px;
    position: absolute;
    left: 0px;
    cursor: pointer;
    height: 100%
}
 
.threeImg .Containt ul li {
    width: 215px;
    margin-right: 40px;
    margin-top: 10px;
    float: left;
      list-style-type:none;
}
 
.threeImg .Containt ul li img {
    height: 120px;
    width: 100%;
    border-radius: 10px;
  
}
 .del-icon {
   
        color: #FF0000;
        margin: 0 0 0 10px;
    }
 
.Containt {
    position: relative;
    width: 1000px;
    height: 130px;
    overflow: hidden;
    margin: 0 auto;
}
 
.iconleft {
    position: absolute;
   width: 25px;
    height: 21px;
    top: 10px;
    z-index: 99999;
       padding-top: 4px;
    background-color: #1AB163 ;
    vertical-align: middle;
    margin-top: 50px;
    border-radius: 20px;
}
 
.iconright {
    position: relative;
    left: 1038px;
    top: 70px;
    background-color: #1AB163 ;
    /*position: absolute;*/
    width: 25px;
    height: 21px;
    top: -120px;
    z-index: 99999;
       padding-top: 4px;
    vertical-align: middle;
    margin-top: 50px;
    border-radius: 20px;
}
</style>